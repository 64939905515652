.preview-video {
    width: 90vw;
    height: 50.625vw;
    max-height: 90vh;
    max-width: 160vh;
    margin: auto;
    position: absolute;
    top:0;bottom:0;
    left:0;right:0;
}

.preview-container {
   height: 90vh;
}

.preview-file {
    max-height: 70vh;
    max-width: 100vh;
    margin: auto;
    position: absolute;
    top:0;bottom:0;
    left:0;right:0;
}